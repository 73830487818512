<template>
  <router-view v-slot="{ Component }">
    <Transition>
      <component :is="Component" v-if="!isLoading" />
      <GeneralLogin v-else />
    </Transition>
  </router-view>
</template>
<script>
import {ref, watch } from 'vue'
import GeneralLogin from './components/GeneralLoading'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'AutoLeads',
  components: {
    GeneralLogin,
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    let isLoading = ref(false)

    /** Obseva a instacia das rotas para verificar se os parametros foram preenchido,
     * enquando isso é exibido o loading
     */
    const unwatch = watch(
      () => route.params,
      (params) => {
        if (window.location.pathname == '/404') {
          return
        }

        if (!params.countryId && !params.instance) {
          window.location = '/404'
        }

        if (!store.state.c2bConfig && !store.state.translate) {
          isLoading.value = true
          store.dispatch('loadC2bConfig', params)
          store.dispatch('loadInit', params)
          watch(() => store.state.c2bConfig, () => store.dispatch('loadTranslate', store.state.c2bConfig))
          store.commit('setCountry', params.countryId)
        }
        
        unwatch();
      }
    );

    const finishLoading = () => {
      if (
        store.state.c2bConfig !== null
        && store.state.init !== null
        && store.state.translate !== null
      ) {
        isLoading.value = false
      }
    }

    watch(() => store.state.c2bConfig, () => finishLoading())
    watch(() => store.state.init, () => finishLoading())
    watch(() => store.state.translate, () => finishLoading())

    return {
      isLoading
    }
  }
}
</script>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>