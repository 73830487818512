import { useToast } from 'vue-toastification'

const toast = useToast()

const types = [
    'SUCCESS',
    'ERROR',
    'WARNING',
]

const notification = (message, type) => {
    if (! types.includes(type)) {
        return toast.error('Tipo de toast inválido')
    }

    switch (type) {
        case 'SUCCESS':
            toast.success(message)
            break

        case 'ERROR':
            toast.error(message)
            break
        
        case 'WARNING':
            toast.warning(message)
            break

        default:
            toast.info(message)
            break
    }
    
}

export default notification