import { createApp } from 'vue'
import store from './store'
import route from './routers'
import Maska from 'maska'
import Toast, { POSITION } from "vue-toastification"
import './assets/css/default.scss'

import App from './App.vue'

createApp(App)
    .use(store)
    .use(route)
    .use(Toast, {position: POSITION.TOP_CENTER})
    .use(Maska)
    .mount('#app')