import { createRouter, createWebHistory } from 'vue-router'

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:countryId/:instance/:entity?',
            component: () => import('@/pages/HomePage'),
        },
        {
            path: '/404',
            component: () => import('@/pages/NotFoundPage'),
        }
    ],
});