import { createStore } from 'vuex'
import api from '@/api'
import convertCountry from '@/common/ConvertCountry'
import masksPhoneJson from '@/common/PhoneMask.json'

export default createStore({
    state: {
        currentPage: 1,
        modalTermsIsOpen: false,
        modalPrivacyIsOpen: false,

        c2bConfig: null,
        translate: null,
        init: null,

        country: {},
        form: null,

        currentEntity: null,
        refereceValue: null,
        vehicleToShow: null,
    },

    mutations: {
        setCountry(state, countryId) {
            let masks = []
            const country = convertCountry(countryId)
            const countryMasks = masksPhoneJson
                .filter(e => e.cc == countryId.toUpperCase())

            countryMasks.map(item => masks.push(item.mask))

            return state.country = {
                ...country[0],
                phoneMasks: masks,
            }
            
        },

        togglePage(state, pageId) {
            if (state.currentPage.value == pageId) {
                return state.currentPage = 0
            }

            state.currentPage = pageId
        },

        modalAction(state, payload) {
            switch(payload.modal) {
                case 'terms':
                    state.modalTermsIsOpen = payload.state
                    break

                case 'privacy':
                    state.modalPrivacyIsOpen = payload.state
                    break
            }
        },

        /** Setando configurações iniciais */
        setC2bConfig: (state, payload) => state.c2bConfig = payload,
        setTranslate: (state, payload) => state.translate = payload,
        setInit: (state, payload) => state.init = payload,
        setForm: (state, payload) => state.form = payload,
        setCurrentEntity: (state, payload) => state.currentEntity = payload,
        setRefereceValue: (state, payload) => state.refereceValue = payload,
        setVehicle: (state, payload) => state.vehicleToShow = payload
    },

    actions: {
        loadC2bConfig({ commit }, payload) {
            api.post('/hg', {
                endpoint: '/c2b/config',
                instanceSlug: payload.instance,
                countryId: payload.countryId,
                tokenType: 'partner'
            })
                .then(data => commit('setC2bConfig', data[0]))
        },

        loadTranslate({ commit }, payload) {

            let tEntity = (payload && 'entities' in payload && payload.entities.length > 0) ? payload.entities[0] : null
            let tEvaluator = (tEntity && 'evaluator' in tEntity) ? tEntity.evaluator : null
            let userId = (tEvaluator && 'id' in tEvaluator) ? tEvaluator.id : null
            let tokenType = (!userId) ? 'partner' : 'user'

            api.post('/hg', {
                endpoint: '/translate/get',
                systemId: 4,
                showData: 1,
                userId: userId,
                tokenType: tokenType
            })
                .then(data => commit('setTranslate', data[0]))
        },

        loadInit({ commit }) {
            api.post('/hg', {
                endpoint: '/auto-lead/init',
                instanceId: 1,
                tokenType: 'partner'
            })
                .then(data => commit('setInit', data[0]))
        },
    },

    getters: {
        getTranslate: (state) => (root) => {
            if (! state.translate) {
                return false
            }

            const findRoot = state.translate.filter(e => e.root === root)

            if (Object.keys(findRoot).length > 0) {
                return findRoot[0].text
            }
            
            return false
        },

        getCurrentEntity: (state) => state.currentEntity,

        getCheckConfigRoot: (state) => (configRoot) => {

            if (!state.c2bConfig) {
                console.error("checkConfigRoot() called before c2bConfig data loaded.")
                console.error("configRoot: '"+configRoot+"'")
                return false
            }

            if(
                !configRoot ||
                state.c2bConfig.schema === undefined ||
                state.c2bConfig.schema.permission === undefined ||
                state.c2bConfig.schema.permission.configScheme === undefined ||
                state.c2bConfig.schema.permission.configScheme.constructor !== Array
            ){
                console.error("checkConfigRoot() cannot obtain the config scheme data")
                console.error("configRoot: '"+configRoot+"'")
                return false
            }

            let configRootsArray = state.c2bConfig.schema.permission.configScheme;
            return configRootsArray.includes(configRoot)
        }
    }
});