const countries = [
    {slug: 'br', id: 76},
    {slug: 'pt', id: 620},
    {slug: 'mx', id: 484},
    {slug: 'us', id: 840},
    {slug: 'es', id: 724},
    {slug: 'ar', id: 32},
    {slug: 'ch', id: 152},
]

export default countries