import axios from 'axios'
import notification from '@/common/Toast'

const url = process.env.NODE_ENV === 'development'
  ? 'http://localhost:9081'
  : '/api'

const api = axios.create({
  baseURL: url,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
})

let isSmartPriceEndpoint = false

api.interceptors.response.use(response => {

  /**
   * Padroniza as mensagens de sucesso
   * Retornando apenas o payload
   */
  return response.data.payload
}, error => {
  const res = JSON.parse(error.config.data)

  if (res.endpoint == '/vehicle/reference-values') {
    isSmartPriceEndpoint = true
  }

  /**
   * Retorna o toast de erro junto com a mensagem
   */
  let { response_message } = error.response.data

  let type = 'ERROR'

  if (response_message == '[HG API Response]: Placa não encontrada!') {
    response_message = 'Placa não encontrada, preencha as informações manualmente.'
    type = 'WARNING'
  }

  const message = isSmartPriceEndpoint
    ? 'Não foi possível precificar esse veículo.'
    : response_message

  notification(message, type)
  return Promise.reject(error)
});


export default api